var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        {
          attrs: {
            title: _vm.isModeCreate
              ? _vm.$t("lbl_cash_in_out_create")
              : _vm.$t("lbl_cash_in_out_view")
          }
        },
        [
          _c(
            "a-form-model",
            {
              ref: "CashForm",
              attrs: {
                model: _vm.formModel,
                rules: _vm.formRules,
                "wrapper-col": { span: 14 },
                "label-col": { span: 6 },
                "label-align": "left",
                layout: "horizontal"
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.formModel.documentNo
                        ? _c(
                            "a-form-model-item",
                            { attrs: { label: _vm.$t("lbl_document_number") } },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.formModel.documentNo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "documentNo", $$v)
                                  },
                                  expression: "formModel.documentNo"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_branch"),
                            prop: "branchId"
                          }
                        },
                        [
                          _c("branch-select", {
                            attrs: { disabled: _vm.disabled.branch },
                            model: {
                              value: _vm.formModel.branchId,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "branchId", $$v)
                              },
                              expression: "formModel.branchId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_type"), prop: "type" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "allow-clear": "",
                                "show-search": false,
                                loading: _vm.loading.type,
                                disabled: _vm.disabled.type
                              },
                              model: {
                                value: _vm.formModel.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "type", $$v)
                                },
                                expression: "formModel.type"
                              }
                            },
                            _vm._l(_vm.optTypes, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(" " + _vm._s(item.label) + " ")
                                      ]),
                                      _vm._v(" " + _vm._s(item.label) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_transaction_date"),
                            prop: "transactionDate"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            staticClass: "w-100",
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              disabled: _vm.disabled.transactionDate,
                              disabledDate: _vm.useDisabledFromTomorrow
                            },
                            model: {
                              value: _vm.formModel.transactionDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "transactionDate", $$v)
                              },
                              expression: "formModel.transactionDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_currency"),
                            prop: "currencyId"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "show-search": "",
                                "allow-clear": "",
                                "filter-option": false,
                                loading: _vm.loading.currency,
                                disabled: _vm.disabled.currency
                              },
                              on: {
                                search: _vm.onSearchCurrency,
                                change: _vm.onChangeCurrency
                              },
                              model: {
                                value: _vm.formModel.currencyId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "currencyId", $$v)
                                },
                                expression: "formModel.currencyId"
                              }
                            },
                            _vm._l(_vm.optCurrencies, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(" " + _vm._s(item.label) + " ")
                                      ]),
                                      _vm._v(" " + _vm._s(item.label) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.show.rates,
                              expression: "show.rates"
                            }
                          ],
                          attrs: { label: _vm.$t("lbl_rates") }
                        },
                        [
                          _c("a-input-number", {
                            attrs: {
                              parser: _vm.reverseFormatNumber,
                              formatter: _vm.formatterNumber,
                              min: 1,
                              precision: _vm.storeBaseDecimalPlace,
                              disabled: _vm.disabled.rate
                            },
                            model: {
                              value: _vm.formModel.rates,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "rates", $$v)
                              },
                              expression: "formModel.rates"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_bank_name"),
                            prop: "companyBankId"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "show-search": "",
                                "allow-clear": "",
                                "filter-option": false,
                                loading: _vm.loading.bank,
                                disabled: _vm.disabled.bankName
                              },
                              on: { search: _vm.onSearchCompanyBank },
                              model: {
                                value: _vm.formModel.companyBankId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "companyBankId", $$v)
                                },
                                expression: "formModel.companyBankId"
                              }
                            },
                            _vm._l(_vm.optCompanyBanks, function(item) {
                              return _c(
                                "a-select-option",
                                { key: item.key, attrs: { value: item.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(" " + _vm._s(item.label) + " ")
                                      ]),
                                      _vm._v(" " + _vm._s(item.label) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_transaction_to_from"),
                            prop: "cashInOutTransfer"
                          }
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              attrs: { disabled: _vm.disabled.transferToFrom },
                              on: { change: _vm.onChangeTransferToFrom },
                              model: {
                                value: _vm.formModel.cashInOutTransfer,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.formModel,
                                    "cashInOutTransfer",
                                    $$v
                                  )
                                },
                                expression: "formModel.cashInOutTransfer"
                              }
                            },
                            [
                              _c(
                                "a-radio",
                                {
                                  attrs: {
                                    value: _vm.TRANSFER_TO_FROM_VALUE.SUPPLIER
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_supplier")) + " "
                                  )
                                ]
                              ),
                              _c(
                                "a-radio",
                                {
                                  attrs: {
                                    value: _vm.TRANSFER_TO_FROM_VALUE.CUSTOMER
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_customer")) + " ")]
                              ),
                              _c(
                                "a-radio",
                                {
                                  attrs: {
                                    value: _vm.TRANSFER_TO_FROM_VALUE.NONE
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("lbl_none")) + " ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.showContactField
                        ? [
                            _c(
                              "a-form-model-item",
                              { attrs: { label: _vm.contactLabel.contact } },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      showSearch: true,
                                      allowClear: true,
                                      disabled: _vm.disabled.contact,
                                      loading: _vm.loading.contact,
                                      "filter-option": false
                                    },
                                    on: {
                                      search: _vm.onSearchContact,
                                      change: _vm.onChangeContact
                                    },
                                    model: {
                                      value: _vm.formModel.supplierOrCustomerId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "supplierOrCustomerId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formModel.supplierOrCustomerId"
                                    }
                                  },
                                  _vm._l(_vm.optContacts, function(data) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: data.key,
                                        attrs: { value: data.value }
                                      },
                                      [
                                        _c(
                                          "a-tooltip",
                                          [
                                            _c("template", { slot: "title" }, [
                                              _vm._v(
                                                " " + _vm._s(data.label) + " "
                                              )
                                            ]),
                                            _vm._v(
                                              " " + _vm._s(data.label) + " "
                                            )
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              { attrs: { label: _vm.contactLabel.bank } },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      showSearch: true,
                                      allowClear: true,
                                      disabled: _vm.disabled.contact,
                                      loading: _vm.loading.contact
                                    },
                                    model: {
                                      value: _vm.formModel.customerSupplierBank,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "customerSupplierBank",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formModel.customerSupplierBank"
                                    }
                                  },
                                  _vm._l(_vm.optContactBanks, function(data) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: data.key,
                                        attrs: { value: data.value }
                                      },
                                      [
                                        _c(
                                          "a-tooltip",
                                          [
                                            _c("template", { slot: "title" }, [
                                              _vm._v(
                                                " " + _vm._s(data.label) + " "
                                              )
                                            ]),
                                            _vm._v(
                                              " " + _vm._s(data.label) + " "
                                            )
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : !_vm.showContactField
                        ? [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.$t("lbl_to_from"),
                                  prop: "transferSource"
                                }
                              },
                              [
                                _c("a-input", {
                                  attrs: {
                                    disabled: _vm.disabled.transferSource
                                  },
                                  model: {
                                    value: _vm.formModel.transferSource,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "transferSource",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.transferSource"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.formModel.referenceNo
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: { label: _vm.$t("lbl_reference_number") }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.formModel.referenceNo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "referenceNo", $$v)
                                  },
                                  expression: "formModel.referenceNo"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_cheque_number") } },
                        [
                          _c("a-input", {
                            attrs: { disabled: _vm.disabled.chequeNumber },
                            model: {
                              value: _vm.formModel.chequeNo,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "chequeNo", $$v)
                              },
                              expression: "formModel.chequeNo"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_bank_name_cheque") } },
                        [
                          _c("a-input", {
                            attrs: { disabled: _vm.disabled.bankNameCheque },
                            model: {
                              value: _vm.formModel.chequeBankName,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "chequeBankName", $$v)
                              },
                              expression: "formModel.chequeBankName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_description"),
                            prop: "description"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "extra",
                              fn: function() {
                                return [
                                  _c("character-length", {
                                    attrs: { value: _vm.formModel.description }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("a-textarea", {
                            attrs: {
                              rows: 4,
                              disabled: _vm.disabled.description
                            },
                            model: {
                              value: _vm.formModel.description,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "description", $$v)
                              },
                              expression: "formModel.description"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_attachment_file"),
                            help:
                              "Maximum file size is 5MB and accepted file extension is PDF, JPG, PNG, XLSX"
                          }
                        },
                        [
                          _c(
                            "a-upload",
                            {
                              attrs: {
                                name: "file",
                                multiple: false,
                                method: "post",
                                "file-list": _vm.fileList,
                                customRequest: _vm.customRequest,
                                "before-upload": _vm.beforeUpload,
                                accept: ".pdf, .png, .jpg, .xlsx"
                              },
                              on: { change: _vm.handleChange }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    disabled: _vm.disabled.file,
                                    icon: "upload"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_upload")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.formModel.status
                        ? _c(
                            "a-form-model-item",
                            { attrs: { label: _vm.$t("lbl_status") } },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.formModel.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "status", $$v)
                                  },
                                  expression: "formModel.status"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.formModel.journalId
                        ? _c(
                            "a-form-model-item",
                            { attrs: { label: _vm.$t("lbl_journal_number") } },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "generaljournal.detail",
                                      params: { id: _vm.formModel.journalId }
                                    }
                                  }
                                },
                                [
                                  _c("a-button", { attrs: { type: "link" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.formModel.journalNumber) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-divider"),
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { icon: "plus", type: "primary" },
                      on: { click: _vm.addRow }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_add_row")))]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "danger" },
                      on: { click: _vm.confirmDelete }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_delete_row")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-table",
            {
              attrs: {
                "row-selection": {
                  selectedRowKeys: _vm.selectedRowKeys,
                  onChange: _vm.onSelectChange
                },
                columns: _vm.columns,
                "data-source": _vm.dataSource,
                pagination: {
                  showTotal: function(total) {
                    return _vm.$t("lbl_total_items", { total: total })
                  }
                },
                size: "small"
              },
              scopedSlots: _vm._u([
                {
                  key: "accountId",
                  fn: function(text, record) {
                    return _c("coa-select", {
                      staticClass: "w-100",
                      attrs: { value: record.accountId },
                      on: {
                        change: function(e) {
                          return (record.accountId = e)
                        }
                      }
                    })
                  }
                },
                {
                  key: "amount",
                  fn: function(text, record) {
                    return _c("a-input-number", {
                      staticClass: "w-100",
                      attrs: {
                        parser: _vm.reverseFormatNumber,
                        formatter: _vm.formatterNumber,
                        min: 0,
                        precision: _vm.storeBaseDecimalPlace
                      },
                      on: {
                        change: function(e) {
                          return _vm.onChangeAmount(e, record)
                        }
                      },
                      model: {
                        value: record.amount,
                        callback: function($$v) {
                          _vm.$set(record, "amount", $$v)
                        },
                        expression: "record.amount"
                      }
                    })
                  }
                },
                {
                  key: "notes",
                  fn: function(text, record) {
                    return _c("a-textarea", {
                      staticClass: "w-100",
                      model: {
                        value: record.notes,
                        callback: function($$v) {
                          _vm.$set(record, "notes", $$v)
                        },
                        expression: "record.notes"
                      }
                    })
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "a-form-model",
                    { attrs: { model: _vm.formTotal, layout: "inline" } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_total") } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.formTotal.currency || "-") + " "
                          )
                        ]
                      ),
                      _c("a-form-model-item", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.formTotal.currencyTotalValue
                              )
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          ),
          _c("a-divider"),
          _c(
            "div",
            { staticClass: "text-right mt-3" },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(_vm._s(_vm.$t("lbl_back")))
                  ]),
                  _vm.allowPrint
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.print
                          },
                          on: { click: _vm.handlePrint }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                      )
                    : _vm._e(),
                  _vm.allowCancel && _vm.$can("update", "cash-in-out")
                    ? [
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: _vm.$t("pop_confirmation"),
                              "ok-text": _vm.$t("lbl_yes"),
                              "cancel-text": _vm.$t("lbl_no")
                            },
                            on: { confirm: _vm.handleCancel }
                          },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  type: "danger",
                                  loading: _vm.loading.cancel
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm.allowSaveDraft && _vm.$can("create", "cash-in-out")
                    ? _c(
                        "a-button",
                        {
                          attrs: { loading: _vm.loading.saveDraft },
                          on: { click: _vm.handleSaveDraft }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lbl_save_as_draft")) + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.allowUpdate && _vm.$can("update", "cash-in-out")
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.update
                          },
                          on: {
                            click: function($event) {
                              return _vm.validateForm("update")
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_update")) + " ")]
                      )
                    : _vm._e(),
                  _vm.allowSubmit &&
                  (_vm.$can("update", "cash-in-out") ||
                    _vm.$can("create", "cash-in-out"))
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.submit
                          },
                          on: {
                            click: function($event) {
                              return _vm.validateForm("submit")
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }